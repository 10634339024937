import React, { Component } from 'react';
import axios from 'axios';
import Thanks from './../../Thanks';
import { Input, Textarea } from './../../forms/input';
class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formdata: {},
      showThanks: false,
      loadedThanks: false,
      website: props.website,
    };
    this.handleChange = this.handleChange.bind(this);
    this.hideThanksModal = this.hideThanksModal.bind(this);
  }
  handleChange(param, value) {
    this.setState(prevState => {
      let formdata = Object.assign({}, prevState.formdata);
      formdata[param] = value;
      return { formdata };
    });
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ ...newProps });
  }
  hideThanksModal() {
    this.setState({
      loadedThanks: false,
      showThanks: false,
    });
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
  }
  onSubmit(e) {
    e.preventDefault();
    let that = this;
    let form_data = new FormData(e.target);
    for (let key in this.state.formdata) {
      form_data.append(key, this.state.formdata[key]);
    }
    form_data.append('country', this.props.country);
    form_data.append('service', this.props.title ? this.props.title + ' Proposal' : 'Paid Advertising ');
    // that.props.hide();
    // that.setState({ showThanks: true });
    document.body.style.overflow = 'hidden';
    axios.post('https://mailer.weprodigi.com/actions/getProposal.php', form_data).then(response => {
      // that.setState({ loadedThanks: true });
      window.location.href = 'https://weprodigi.com/thank-you';
    });
  }
  hide = () => {
    document.querySelectorAll('[name=email]').forEach(function (el) {
      el.value = '';
    });
    document.querySelectorAll('[name=company-name]').forEach(function (el) {
      el.value = '';
    });
    document.querySelectorAll('[name=phone-number]').forEach(function (el) {
      el.value = '';
    });
    document.querySelectorAll('[name=website]').forEach(function (el) {
      el.value = '';
    });
    document.querySelectorAll('[name=name]').forEach(function (el) {
      el.value = '';
    });
    document.querySelectorAll('[name=nda]').forEach(function (el) {
      el.checked = false;
    });
    document.querySelectorAll('[name=description]').forEach(function (el) {
      el.value = '';
    });
    this.props.hide();
  };
  render() {
    return (
      <>
        <div
          className={
            'fixed z-900 bg-navbar w-full h-full left-0 top-0 right-0 bottom-0 items-center justify-center' +
            ' ' +
            (this.props.show && !this.state.showThanks ? 'flex' : 'hidden')
          }
        >
          <div className="absolute left-0 top-0 w-full h-full" onClick={this.hide} />
          <div className="relative transform w-11/12 max-w-screen-md max-h-screen-md bg-gray border border-gray-transparent rounded-5 md:scale-100">
            <div
              onClick={this.hide}
              className="absolute -right-12 -top-12 bg-purple text-white w-30 h-30 text-21 rounded-full flex items-center justify-center cursor-pointer select-none"
            >
              <span className="font-bold">X</span>
            </div>
            <div className="py-23 px-50">
              <p className="text-purple uppercase text-24 text-center font-black leading-none">
                {this.props.from == 'contact' ? 'Contact Us' : 'Get Proposal'}
              </p>
              <p className="text-black   text-16 text-center font-black mt-5 mb-15 leading-none uppercase">
                {' '}
                {this.props.title ? this.props.title : this.props.from == 'contact' ? '' : 'Paid ADVERTISING'}
              </p>
              <form id={!this.props.id ? 'offer-form' : 'form-ppc'} method="POST" onSubmit={e => this.onSubmit(e)}>
                <div className="mt-20">
                  <div className="mb-10">
                    <div className="grid grid-cols-2 gap-10">
                      <div>
                        <p className="text-14  mb-5 text-purple  ">Company Name*</p>
                        <div className="mb-10">
                          <Input type="text" name="company-name" bg="bg-gray-light" required={true} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div>
                        <p className="text-14  mb-5 text-purple  ">Company Website*</p>
                        <div className="mb-10">
                          <input
                            type="text"
                            name="website"
                            className={'w-full rounded-5 pl-15 py-5'}
                            bg="bg-gray-light "
                            required={true}
                            onChange={e => {
                              {
                                this.handleChange('website', e.target.value);
                                this.setState({ website: e.target.value });
                              }
                            }}
                            value={this.state.website}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    <p className="text-14 mb-5 text-purple">Describe Your Project</p>
                    <div className="mb-10">
                      <Textarea
                        type="text"
                        name="description"
                        bg="bg-gray-light resize-none"
                        required={false}
                        rows={4}
                        placeholder=""
                        onChange={this.handleChange}
                      ></Textarea>
                    </div>
                  </div>
                  <div className="mb-10">
                    <div className="grid grid-cols-3 gap-10">
                      <div>
                        <p className="text-14 mb-5 text-purple">Your Name *</p>
                        <div className="mb-10">
                          <Input type="text" name="name" bg="bg-gray-light" required={true} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div>
                        <p className="text-14 mb-5 text-purple  ">Your Email *</p>
                        <div className="mb-10">
                          <Input type="text" name="email" bg="bg-gray-light" required={true} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div>
                        <p className="text-14 mb-5 text-purple ">Your Phone Number </p>
                        <div className="mb-10">
                          <Input type="number" name="phone-number" bg="bg-gray-light" required={false} onChange={this.handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row my-10">
                    <div className="form-group mr-30">
                      <label className="relative pl-30 cursor-pointer text-black text-14 md:text-18 flex items-center">
                        <input
                          id="nda"
                          className="absolute cursor-pointer opacity-0"
                          onChange={e => this.handleChange('nda', e.target.checked ? 'yes' : 'No')}
                          type="checkbox"
                          name="nda"
                        />
                        <span className="checkmark absolute left-0 w-20 h-20 border border-black rounded-sm" />
                        Send NDA
                      </label>
                    </div>
                  </div>
                  <input type="hidden" name="country" value={this.props.country} />
                  <div className="col-span-2">
                    <button
                      id="offer-submit"
                      type="submit"
                      className="cursor-pointer mx-auto text-white bg-green text-18 mt-10 px-23 py-3 rounded-5 block "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Thanks show={this.state.showThanks} loaded={this.state.loadedThanks} hide={this.hideThanksModal}>
          Thank you for reaching out to us! One of our account managers will contact you and confirm your request. If you don’t receive an email from
          us within 24 hours please check your Spam Box.
        </Thanks>
      </>
    );
  }
}
export default Offer;
